import React from 'react';

const LifeBuoy: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.22289 4.22289C4.61342 3.83237 5.24658 3.83237 5.63711 4.22289L9.87711 8.46289C10.2676 8.85342 10.2676 9.48658 9.87711 9.87711C9.48658 10.2676 8.85342 10.2676 8.46289 9.87711L4.22289 5.63711C3.83237 5.24658 3.83237 4.61342 4.22289 4.22289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1229 14.1229C14.5134 13.7324 15.1466 13.7324 15.5371 14.1229L19.7771 18.3629C20.1676 18.7534 20.1676 19.3866 19.7771 19.7771C19.3866 20.1676 18.7534 20.1676 18.3629 19.7771L14.1229 15.5371C13.7324 15.1466 13.7324 14.5134 14.1229 14.1229Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7771 4.22289C20.1676 4.61342 20.1676 5.24658 19.7771 5.63711L15.5371 9.87711C15.1466 10.2676 14.5134 10.2676 14.1229 9.87711C13.7324 9.48658 13.7324 8.85342 14.1229 8.46289L18.3629 4.22289C18.7534 3.83237 19.3866 3.83237 19.7771 4.22289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0671 4.93289C19.4576 5.32342 19.4576 5.95658 19.0671 6.34711L15.5371 9.87711C15.1466 10.2676 14.5134 10.2676 14.1229 9.87711C13.7324 9.48658 13.7324 8.85342 14.1229 8.46289L17.6529 4.93289C18.0434 4.54237 18.6766 4.54237 19.0671 4.93289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87711 14.1229C10.2676 14.5134 10.2676 15.1466 9.87711 15.5371L5.63711 19.7771C5.24658 20.1676 4.61342 20.1676 4.22289 19.7771C3.83237 19.3866 3.83237 18.7534 4.22289 18.3629L8.46289 14.1229C8.85342 13.7324 9.48658 13.7324 9.87711 14.1229Z"
      />
    </svg>
  );
};

export default LifeBuoy;
